import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import CommonSwiper from "../common/swiper/CommonSwiper";
import { getCustomCards } from "../../utlis/getCustomCards";

function SwiperSection({ homePage }) {
  const [slides, setSlides] = useState([]);
  useEffect(() => {
    setSlides(getCustomCards(homePage, "slideShow"));
  }, [homePage]);
  const slidesColors = [
    "#f1f1f1",
    "#82b5ff",
    "#335fae",
    "#488cee",
    "#e5e5e5",
    "#aed2fc",
  ];
   const handleBackgroundColor = (i) => {
     const backgroundColors = [
       "#f1f1f1",
       "#82b5ff",
       "#335fae",
       "#488cee",
       "#e5e5e5",
       "#aed2fc",
     ];
     if (!backgroundColors[i]) {
       return backgroundColors[slides.length - i - 1];
     }
     return backgroundColors[i];
   };
   return (
     <Row className="my-5">
       <Col>
         {slides.length && (
           <CommonSwiper
             sliderData={slides}
             slidesColors={slidesColors}
             handleBackgroundColor={handleBackgroundColor}
           />
         )}
       </Col>
     </Row>
   );
}

export default SwiperSection;
