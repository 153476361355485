const aboutUsRoute = "/";
const aboutUsRoute2 = "/About-us";
const notFoundRoute = "/*";
const blogRoute = "https://blog.i-dare.org/";
const campaignsRoute = "/campaigns";
const singleCampaignRoute = "/campaigns/:id";
const programRoute = "/programs";
const makerspaceRoute = "/makerspace";
const singleProgramRoute = "/programs/:id";
const iDareEventWebsite = "https://portal.i-dare.org/";
const daringHubRoute = "/daring-hub";
const whoWeAre = "#who_we_are";
const partners = "#partners";
const approach = "#approach";
const team = "#team";

export const routes = {
  aboutUsRoute,
  campaignsRoute,
  singleCampaignRoute,
  iDareEventWebsite,
  programRoute,
  singleProgramRoute,
  notFoundRoute,
  whoWeAre,
  partners,
  approach,
  blogRoute,
  daringHubRoute,
  team,
  aboutUsRoute2,
  makerspaceRoute,
};
