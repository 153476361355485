import React from "react";
import PagesRoutes from "./pagesRoutes";

function MobileHeader({ openMenu, setOpenMenu, programs }) {
  return (
    <div className={`burger-menu_list${openMenu ? " active" : " inactive"}`}>
      <PagesRoutes setOpenMenu={setOpenMenu} programs={programs} />
    </div>
  );
}

export default MobileHeader;
