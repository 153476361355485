import React, { useEffect, useState } from "react";
import { CiMenuBurger } from "react-icons/ci";
import MobileHeader from "./MobileHeader";

function BurgerMenu({ programs }) {
  const [openMenu, setOpenMenu] = useState(false);
  const handleOpenMenu = () => {
    setOpenMenu(!openMenu);
  };
  useEffect(() => {
    const header = document.getElementById("header");
    if (openMenu)
      header.style =
        "background: linear-gradient(var(--light-gray),var(--light-gray)";
    else header.style = "";
  }, [openMenu]);
  return (
    <>
      <div className="burger-menu">
        <CiMenuBurger
          className={`burger-menu_icon${openMenu ? " active" : ""}`}
          onClick={handleOpenMenu}
        />
        <MobileHeader
          openMenu={openMenu}
          setOpenMenu={setOpenMenu}
          programs={programs}
        />
      </div>
    </>
  );
}

export default BurgerMenu;
