import React from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import LightBlueContainer from "../common/light-blue-container/LightBlueContainer";
import AgencySections from "./AgencySections";
import ServerImage from "../common/server-images/ServerImage";
import { getDataByApiName } from "../../utlis/getDataByApiName";
import DomParser from "../common/domParser/DomParser";
function YouthAgency({ homePage }) {
  const isArabic = useSelector((state) => state.language.isArabic);
  const youthAgencyImg = getDataByApiName(
    homePage[0],
    "images",
    "youthAgencyImg"
  );
  const youthAgencyTitle = getDataByApiName(
    homePage[0],
    "texts",
    "youthAgencyTitle"
  );
  const youthAgencyDesc = getDataByApiName(
    homePage[0],
    "texts",
    "youthAgencyDesc"
  );
  return (
    <>
      <LightBlueContainer showArrow={true}>
        <Col sm={11} className="agency-img-conainer">
          {youthAgencyImg.active && youthAgencyImg.image ? (
            <Row className="align-items-start justify-content-center">
              <ServerImage
                src={youthAgencyImg.image}
                alt={isArabic ? youthAgencyImg.alt_ar : youthAgencyImg.alt}
              />
            </Row>
          ) : (
            <></>
          )}
          {youthAgencyTitle.active && (
            <Row className="justify-content-center align-items-start mt-3">
              <p className="title">
                <DomParser
                  htmlResponse={
                    isArabic
                      ? youthAgencyTitle.content_ar
                      : youthAgencyTitle.content
                  }
                  className="border"
                />
              </p>
            </Row>
          )}
          {youthAgencyDesc && (
            <Row className="justify-content-center align-items-start">
              <p className="description">
                <DomParser
                  htmlResponse={
                    isArabic
                      ? youthAgencyDesc.content_ar
                      : youthAgencyDesc.content
                  }
                />
              </p>
            </Row>
          )}
        </Col>
      </LightBlueContainer>
      <AgencySections homePage={homePage} />
    </>
  );
}

export default YouthAgency;
