import React, { useEffect } from "react";
import Aos from "aos";
function AosContainer({ delay, children, style, dataAos, ...rest }) {
  useEffect(() => {
    Aos.init({
      duration: 400,
      easing: "ease-in-out",
      once: true,
    });
  }, []);
  return (
    <div className="aos-container" data-aos={dataAos} style={style} {...rest}>
      {children}
    </div>
  );
}

export default AosContainer;
