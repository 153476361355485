import React from "react";
import { useTranslation } from "react-i18next";
import NavItems from "../common/navs/NavItems";
import LanguageSwitcher from "./LanguageSwitcher";
import { getPagesLinks } from "./pagesLinks";

function PagesRoutes({ setOpenMenu, programs }) {
  const { t } = useTranslation();
  const pagesRoutes = getPagesLinks(programs, t);
  return (
    <>
      <NavItems links={pagesRoutes} setOpenMenu={setOpenMenu} />
      {setOpenMenu && <LanguageSwitcher setOpenMenu={setOpenMenu} />}
    </>
  );
}

export default PagesRoutes;
