import React from "react";
import FirstPageSection from "../../components/common/firstPageSection/FirstPageSection";
import MainLayout from "../../components/layout/MainLayout";
import DomParser from "../../components/common/domParser/DomParser";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { getDataByApiName } from "../../utlis/getDataByApiName";

function MakerSpace({ makerspacePage }) {
  const { isArabic } = useSelector((state) => state.language);
  const firstSectionImage = getDataByApiName(
    makerspacePage,
    "images",
    "makerspace-img"
  );
  const firstSectionTitle = getDataByApiName(
    makerspacePage,
    "texts",
    "makerspace-first-text"
  );
  const firstSectionInfo = getDataByApiName(
    makerspacePage,
    "texts",
    "makerspace-content"
  );
  return (
    <>
      <MainLayout addMargin={false}>
        <FirstPageSection
          firstSectionTitle={firstSectionTitle}
          sectionImage={firstSectionImage}
        />
      </MainLayout>
      <MainLayout>
        <Row className="my-3">
          <Col>
            <DomParser
              htmlResponse={
                isArabic
                  ? firstSectionInfo.content_ar
                  : firstSectionInfo.content
              }
            />
          </Col>
        </Row>
      </MainLayout>
    </>
  );
}

export default MakerSpace;
