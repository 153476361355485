import React, { memo } from "react";
import RightHeaderSide from "./RightHeaderSide";
import LeftHeaderSide from "./LeftHeaderSide";
import MiddleHeaderSide from "./MiddleHeaderSide";
import { Col, Row } from "react-bootstrap";
const Header = memo(function ({ programs }) {
  return (
    <Row className="px-2">
      <Col xs={12} sm={12}>
        <Row>
          <Col>
            <header>
              <LeftHeaderSide />
              <MiddleHeaderSide programs={programs} />
              <RightHeaderSide programs={programs} />
            </header>
          </Col>
        </Row>
      </Col>
    </Row>
  );
});

export default Header;
