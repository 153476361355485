import { routes } from "../../routes/Routes";

export const getPagesLinks = (programs, t) => {
  const dropDownMenu = programs.map((p) => {
    return {
      id: p.id,
      title: p.title,
      title_ar: p.title_ar,
      path: routes.programRoute + `/${p.id}`,
    };
  });
  return [
    {
      label: t("about-us"),
      path: routes.aboutUsRoute,
      path2: routes.aboutUsRoute2,
    },
    { label: t("campaings"), path: routes.campaignsRoute },
    { label: t("programs"), dropDownMenu: dropDownMenu },
    { label: t("blog"), href: routes.blogRoute },
    { label: t("m-space"), path: routes.makerspaceRoute },
    {
      id: "multiple-navs",
      nav1: {
        label: t("join-us"),
        href: routes.iDareEventWebsite,
      },
      nav2: {
        label: t("daring-hub"),
        path: routes.daringHubRoute,
      },
    },
  ];
};
