import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Header from "./components/header/Header";
import MainLayout from "./components/layout/MainLayout";
import MainRoutes from "./routes/MainRoutes";
import Footer from "./components/footer/Footer";
import { getHomePage, getLogos } from "./services/homeService";
import LoadingIcon from "./components/common/loading-icon/LoadingIcon";
import i18n from "./i18n";
import { useSelector } from "react-redux";
import { getCampaigns, getCampaignsPage } from "./services/campaignsService";
import { getPrograms } from "./services/programService";
import { ToastContainer } from "react-toastify";
import { getDaringHubPage } from "./services/daringHubService";
import { useTranslation } from "react-i18next";
import Agree from "./components/agree/Agree";
import { getMakerSpacePage } from "./services/makerSpaceService";

function App() {
  const { t } = useTranslation();
  const [homePage, setHomePage] = useState([]);
  const [campaignsPage, setCampaignsPage] = useState([]);
  const [makerspacePage, setMakerspacePage] = useState([]);
  const [daringPage, setDaringPage] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [logos, setLogos] = useState([]);

  const isSwitched = useSelector((state) => state.language.isSwitched);
  useEffect(() => {
    document.documentElement.lang = i18n.language;
  }, [isSwitched]);

  useEffect(() => {
    const getPagesData = async () => {
      setIsLoading(true);
      const { data: home } = await getHomePage(t);
      const { data: campaigns } = await getCampaignsPage(t);
      const { data: makerspace } = await getMakerSpacePage(t);
      const { data: campaignsList } = await getCampaigns(t);
      const { data: daringHub } = await getDaringHubPage(t);
      const { data: programs } = await getPrograms(t);
      const { data: logos } = await getLogos(t);
      setLogos(logos);
      setHomePage(home);
      setCampaignsPage(campaigns);
      setMakerspacePage(makerspace);
      setDaringPage(daringHub);
      setPrograms(programs);
      setCampaigns(campaignsList);
      setIsLoading(false);
    };
    getPagesData();
  }, []);

  if (!isLoading) {
    return (
      <>
        <ToastContainer />
        <Container fluid>
          <MainLayout className="fixed-header" id="header">
            <Header programs={programs} />
          </MainLayout>
          <MainLayout>
            <MainRoutes
              programs={programs}
              homePage={homePage}
              logos={logos}
              campaignsPage={campaignsPage}
              campaignsList={campaigns}
              daringPage={daringPage}
              makerspacePage={makerspacePage}
            />
          </MainLayout>
          <Agree />

          <Footer
            homePage={homePage}
            campaignsList={campaigns}
            programs={programs}
          />
        </Container>
      </>
    );
  }
  return <LoadingIcon />;
}

export default App;
