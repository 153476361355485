import React from "react";
import { Nav } from "react-bootstrap";
import PagesRoutes from "./pagesRoutes";

function MiddleHeaderSide({ programs }) {
  return (
    <Nav className="header_middle-section">
      <PagesRoutes programs={programs} />
    </Nav>
  );
}

export default MiddleHeaderSide;
