import React from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import DomParser from "../domParser/DomParser";
import AosContainer from "../Aos/Aos";
import ServerImage from "../server-images/ServerImage";

function FirstPageSection({
  isHome,
  sectionImage,
  firstSectionInfo,
  firstSectionTitle,
  children,
  ...rest
}) {
  const isArabic = useSelector((state) => state.language.isArabic);

  return (
    <section className="first-page-section" {...rest}>
      <AosContainer dataAos="slide-right">
        {sectionImage && (
          <Row className="justify-content-center">
            <Col>
              {sectionImage.active && sectionImage.image ? (
                <ServerImage
                  id="who_we_are"
                  src={sectionImage.image}
                  alt={isArabic ? sectionImage.alt_ar : sectionImage.alt}
                  loading="lazy"
                  className="first-page-section_image"
                />
              ) : (
                <></>
              )}
            </Col>
          </Row>
        )}

        <Row className="justify-content-center" style={{ textAlign: "center" }}>
          {firstSectionTitle && firstSectionTitle.active ? (
            <Col
              xs={11}
              sm={10}
              md={10}
              lg={10}
              className={`first-page-section_idare_title pt-3 pb-0 mb-0 ${
                isArabic ? "ar" : ""
              }`}
            >
              <DomParser
                htmlResponse={
                  isArabic
                    ? firstSectionTitle.content_ar
                    : firstSectionTitle.content
                }
                className="border"
              />
            </Col>
          ) : (
            <></>
          )}
          {firstSectionInfo && firstSectionInfo.active ? (
            <Col
              xs={11}
              sm={10}
              md={10}
              lg={8}
              className="first-page-section_idare_intro"
            >
              <DomParser
                htmlResponse={
                  isArabic
                    ? firstSectionInfo.content_ar
                    : firstSectionInfo.content
                }
                className="border"
              />
            </Col>
          ) : (
            <></>
          )}
        </Row>
        {children}
      </AosContainer>
    </section>
  );
}

export default FirstPageSection;
