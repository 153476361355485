import axios from "axios";
import { Toast } from "../components/common/toast/Toast";
import { savedLanguage } from "../i18n";


export const storageBaseURL = process.env.REACT_APP_STORAGE_BASE_URL;

export const FAILED = "FAILED";
export const SUCCESS = "SUCCESS";
axios.interceptors.response.use(null, (error) => {
  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500;

  if (!expectedError) {
    Toast(
      "error",
      savedLanguage === "en"
        ? "An unexpected error occurrred."
        : "حدث خطأ غير متوقع"
    );
  }
  return Promise.reject(error);
});

export const http = {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
};
